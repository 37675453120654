import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Table,
  Spinner,
  Modal,
} from "react-bootstrap";
import { api } from "../services/api";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const QST = () => {
  // Add background style
  document.body.classList.add("riveBackground");

  const version = "1.4.0";
  const [DEBUGMESSAGE, SETDEBUGMESSAGE] = useState("");

  const [qstSocket, setQstSocket] = useState(null);
  const [rvtSocket, setRvtSocket] = useState(null);

  const [qstConnected, setQstConnected] = useState(false);
  const [rvtConnected, setRvtConnected] = useState(false);

  const [uniqueAccounts, setUniqueAccounts] = useState();
  const [startingExecution, setStartingExecution] = useState(false);

  const [IsUnauthorized, setIsUnauthorized] = useState(false);
  const autoReconnect = useRef(true);
  const autoReconnecting = useRef(false);
  const autoReconnectCount = useRef(0);

  const [isDemo, setIsDemo] = useState(true);
  const [setting, setSetting] = useState();
  const [activeStrategy, setActiveStrategy] = useState([]);
  const [strategy, setStrategy] = useState([]);

  // Current form option
  const [formAccount, setFormAccount] = useState();
  const [formProduct, setFormProduct] = useState();
  const [formStrategy, setFormStrategy] = useState();
  const [formStrategyName, setFormStrategyName] = useState();
  const [formLotSize, setFormLotSize] = useState(1);
  const [formType, setFormType] = useState("LMT");
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [productToReset, setProductToReset] = useState([]);

  const [transactionLogs, setTransactionLogs] = useState([]);
  const executeAction = useRef([]);
  const currentOrders = useRef([]);
  const currentQuotes = useRef([]);
  const currentPosition = useRef([]);
  const currentOrderID = useRef([]);

  // Problem: If Connection is lost, it will not reconnect
  // Problem: If Connection is lost, and already enter position then it wont detect the exit
  useEffect(() => {
    const pastMode = localStorage.getItem("pastMode");
    if (pastMode) {
      setIsDemo(pastMode === "DEMO");
    }
    onQSTConnectionMake();
  }, []);

  useEffect(() => {
    if (qstSocket) {
      qstSocket.onopen = () => {
        var validateString = validateHttp();
        if (!processValidate(validateString)) {
          qstSocket.close();
          setQstConnected(false);
          console.log("Your connection to QST could not be initialized.");
          return;
        } else {
          setQstConnected(true);
        }
        // QST - checkVersion;
        var request = {
          RQT: "version",
          VN: "1.0.4",
        };
        sendQSTMessage(JSON.stringify(request));

        sendQSTMessage(
          JSON.stringify({
            RQT: "accounts",
            PV: isDemo ? "PTS" : "QOR",
          })
        );

        sendQSTMessage(
          JSON.stringify({
            RQT: "oe_register",
          })
        );
      };

      qstSocket.onerror = (error) => {
        console.log("QST Socket error:", error);
        if (qstSocket) {
          qstSocket.close();
          setQstConnected(false);
          setQstSocket(null);
        }
      };

      qstSocket.onclose = () => {
        console.log("QST Socket connection closed.");
        setQstConnected(false);
        setQstSocket(null);
      };

      qstSocket.onmessage = (event) => {
        const newMessage = event.data;
        var jsonValidate = JSON.parse(newMessage);
        if (jsonValidate !== undefined) {
          if (jsonValidate.RQT === "accounts") {
            var tmp = [];
            jsonValidate.ACCOUNTS.forEach((element) => {
              tmp.push({
                account: element.AC,
                name: element.NM,
                provider: element.PV,
              });
            });

            api
              .postDataNoRefresh("qst/login", tmp)
              .then((res) => {
                setSetting(res);
                if (res.length === 1) {
                  setFormAccount(res[0].account);
                }

                var newStrat = [];
                const pastStrategy = localStorage.getItem("pastStrategy");
                const pastStrategyVersion = localStorage.getItem(
                  "pastStrategyVersion"
                );
                if (pastStrategy && pastStrategyVersion === version) {
                  const pastStratObj = JSON.parse(pastStrategy);
                  pastStratObj.forEach((x) => {
                    res.forEach((y) => {
                      if (y.account === x.account) {
                        y.strategy.forEach((z) => {
                          if (z._id === x.strategy) {
                            x.strategy_name = z.name;
                            x.qstCode = z.qstCode;
                            x.tickSizeLMT = z.tickSizeLMT;
                            x.tickDifferenceLMT = z.tickDifferenceLMT;
                            x.tickSizeMKT = z.tickSizeMKT;
                            x.tickDifferenceMKT = z.tickDifferenceMKT;
                            x.positionFailsafe = z.positionFailsafe;
                            x.LMTExitTimeout = z.LMTExitTimeout
                              ? z.LMTExitTimeout
                              : 0;
                            x.LMTEnterTimeout = z.LMTEnterTimeout
                              ? z.LMTEnterTimeout
                              : 0;

                            newStrat = [...newStrat, x];
                          }
                        });
                      }
                    });
                  });
                  setStrategy(newStrat);
                }
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 401) {
                    setSetting(null);
                    alert("Unauthorized");
                    setIsUnauthorized(true);
                    if (qstSocket) qstSocket.close();
                  }
                }
              });
          } else if (jsonValidate.RQT === "place_order") {
            if (jsonValidate.RES !== "OK") {
              setTransactionLogs((prevLogs) => [
                ...prevLogs,
                {
                  index: prevLogs.length,
                  type: "ERROR",
                  message: jsonValidate.MSG,
                  date: new Date().toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }),
                },
              ]);
            }
          } else if (jsonValidate.UPD === "Order") {
            // Order status here
            if (jsonValidate.ST === "PN") {
              const orderRequested = executeAction.current.find(
                (x) =>
                  x.account.toString() === jsonValidate.AC &&
                  x.product.toString() === jsonValidate.INS &&
                  x.action.toString() === jsonValidate.SD &&
                  x.quantity.toString() === jsonValidate.QT
              );

              if (orderRequested) {
                // Remove orderRequested from executeAction
                executeAction.current = executeAction.current.filter(
                  (x) => x !== orderRequested
                );

                currentOrderID.current.push({
                  uuid: orderRequested.uuid,
                  orderID: jsonValidate.OD,
                });

                // Add to transactionLogs
                setTransactionLogs((prevLogs) => [
                  ...prevLogs,
                  {
                    index: prevLogs.length,
                    type: "MESSAGE",
                    status: "PENDING",
                    orderID: jsonValidate.OD,
                    account: jsonValidate.AC,
                    product: jsonValidate.INS,
                    action: jsonValidate.SD,
                    quantity: jsonValidate.QT,
                    price: jsonValidate.TP === "MKT" ? "MKT" : jsonValidate.PR,
                    position: orderRequested.position,
                    date: new Date().toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }),
                  },
                ]);

                currentOrders.current.push({
                  orderID: jsonValidate.OD,
                  product: jsonValidate.INS,
                  account: jsonValidate.AC,
                  price: jsonValidate.PR,
                  action: jsonValidate.SD,
                  tickSizeLMT: orderRequested.tickSizeLMT,
                  tickDifferenceLMT: orderRequested.tickDifferenceLMT,
                  position: orderRequested.position,
                  quantity: jsonValidate.QT,
                });

                currentPosition.current = currentPosition.current.map((y) => {
                  if (
                    y.account === jsonValidate.AC &&
                    y.product === jsonValidate.INS &&
                    y.fulfilled === false &&
                    y.created === false
                  ) {
                    return {
                      ...y,
                      fulfilled: false,
                      created: true,
                    };
                  }
                  return y;
                });
              }
            } else {
              var status = "";

              if (jsonValidate.ST === "N") {
                status = "ORDER";
              } else if (jsonValidate.ST === "F") {
                status = "FILLED";
              } else {
                // Update transaction logs
                switch (jsonValidate.ST) {
                  case "PCXL":
                    status = "PENDING CANCEL";
                    break;
                  case "CXL":
                    status = "CANCELLED";
                    break;
                  case "PRJ":
                    status = "PENDING REJECT";
                    break;
                  case "RJ":
                  case "RJB":
                    status = "REJECTED";
                    break;
                  default:
                    status = "UNKNOWN - " + jsonValidate.ST;
                    break;
                }
              }

              if (status === "CANCELLED" || status === "REJECTED") {
                // Remove from currentOrders
                const order = currentOrders.current.find(
                  (x) => x.orderID === jsonValidate.OD
                );
                if (order) {
                  if (order.position.startsWith("ENTER")) {
                    currentPosition.current = currentPosition.current.filter(
                      (y) =>
                        y.account !== jsonValidate.AC &&
                        y.product !== jsonValidate.INS
                    );
                  }
                }

                currentOrders.current = currentOrders.current.filter(
                  (x) => x.orderID !== jsonValidate.OD
                );
              } else if (status === "FILLED") {
                // update current position to fulfilled
                currentPosition.current = currentPosition.current.map((y) => {
                  if (
                    y.account === jsonValidate.AC &&
                    y.product === jsonValidate.INS
                  ) {
                    return {
                      ...y,
                      fulfilled: true,
                    };
                  }
                  return y;
                });

                currentOrderID.current = currentOrderID.current.filter(
                  (y) => y.orderID !== jsonValidate.OD
                );
              }

              setTransactionLogs((prevLogs) => {
                const newLogs = prevLogs.map((x) => {
                  if (x.orderID === jsonValidate.OD) {
                    return {
                      index: x.index,
                      type: "MESSAGE",
                      status: x.status.startsWith("CANCELLED")
                        ? x.status
                        : status,
                      account: jsonValidate.AC,
                      product: jsonValidate.INS,
                      orderID: jsonValidate.OD,
                      action: jsonValidate.SD,
                      quantity: jsonValidate.QT,
                      price:
                        jsonValidate.TP === "MKT" ? "MKT" : jsonValidate.PR,
                      position: x.position,
                      date: x.date,
                    };
                  }
                  return x;
                });
                return newLogs;
              });
            }
          } else if (jsonValidate.UPD === "Fill") {
            setTransactionLogs((prevLogs) => {
              const newLogs = prevLogs.map((x) => {
                if (
                  x.orderID === jsonValidate.OD &&
                  x.product === jsonValidate.INS
                ) {
                  // update current position to fulfilled
                  currentPosition.current = currentPosition.current.map((y) => {
                    if (
                      y.account === x.account &&
                      y.product === jsonValidate.INS
                    ) {
                      return {
                        ...y,
                        fulfilled: true,
                      };
                    }
                    return y;
                  });

                  return {
                    index: x.index,
                    type: "MESSAGE",
                    status: "FILLED",
                    account: x.account,
                    product: x.product,
                    orderID: x.orderID,
                    action: x.action,
                    quantity: x.quantity,
                    price: jsonValidate.PR,
                    position: x.position,
                    date: x.date,
                  };
                }
                return x;
              });
              return newLogs;
            });

            // Set current position to fulfilled
            currentPosition.current = currentPosition.current.map((y) => {
              if (
                y.account === jsonValidate.AC &&
                y.product === jsonValidate.INS
              ) {
                return {
                  ...y,
                  fulfilled: true,
                };
              }
              return y;
            });

            // Remove from currentOrders
            currentOrders.current = currentOrders.current.filter(
              (x) => x.orderID !== jsonValidate.OD
            );

            currentOrderID.current = currentOrderID.current.filter(
              (y) => y.orderID !== jsonValidate.OD
            );

            // TODO: Send log back to RVT
          } else if (jsonValidate.RQT === "register") {
            if (
              !currentQuotes.current.find(
                (item) => item.product === jsonValidate.INS
              )
            ) {
              currentQuotes.current.push({
                product: jsonValidate.INS,
                ask: jsonValidate.AK,
                bid: jsonValidate.BD,
              });
            } else {
              currentQuotes.current = currentQuotes.current.map((item) => {
                if (item.product === jsonValidate.INS) {
                  return {
                    ...item,
                    ask: jsonValidate.AK,
                    bid: jsonValidate.BD,
                  };
                }
                return item;
              });
            }
          } else if (jsonValidate.UPD === "Quote") {
            // Check for LMT orders and cancel if exceed tick difference
            currentOrders.current.forEach((x) => {
              if (
                x.product === jsonValidate.INS &&
                x.tickDifferenceLMT * x.tickSizeLMT > 0
              ) {
                if (
                  (x.action === "B" &&
                    Math.abs(jsonValidate.AK - x.price) >
                      Math.abs(x.tickDifferenceLMT * x.tickSizeLMT)) ||
                  (x.action === "S" &&
                    Math.abs(jsonValidate.BD - x.price) >
                      Math.abs(x.tickDifferenceLMT * x.tickSizeLMT))
                ) {
                  sendQSTMessage(
                    JSON.stringify({
                      RQT: "cancel_order",
                      PV: isDemo ? "PTS" : "QOR", // Provider
                      AC: x.account, // Account
                      OD: x.orderID, // Order Id
                      CNF: "OFF", // [Optional] DEF/ON/OFF
                    })
                  );

                  if (x.position.startsWith("EXIT")) {
                    const foundOrder = currentOrderID.current.find(
                      (y) => y.orderID === x.orderID
                    );

                    setTransactionLogs((prevLogs) => {
                      const newLogs = prevLogs.map((z) => {
                        if (
                          z.orderID ===
                          currentOrderID.current.find(
                            (y) => y.uuid === foundOrder.uuid
                          ).orderID
                        ) {
                          return {
                            index: z.index,
                            type: "MESSAGE",
                            status: "CANCELLED - Switch to MKT order",
                            account: z.account,
                            product: z.product,
                            orderID: z.orderID,
                            action: z.action,
                            quantity: z.quantity,
                            price: z.price,
                            position: z.position,
                            date: z.date,
                          };
                        }
                        return x;
                      });
                      return newLogs;
                    });

                    sendQSTMessage(
                      JSON.stringify({
                        RQT: "place_order", // Request Type
                        PV: isDemo ? "PTS" : "QOR", // Provider
                        AC: x.account, // Account
                        SD: x.action, // Side: B / S
                        QT: x.quantity, // Quantity
                        INS: x.product, // Instrument
                        TP: "MKT", // Order Type
                        PR: x.price, // Price
                        LM: x.price, // Limit
                        LF: "DAY", // Lifetime: DAY, GTC, GTD(mm/dd/yyyy)
                        CNF: "OFF", // [Optional] DEF/ON/OFF
                      })
                    );

                    executeAction.current.push({
                      uuid: foundOrder.uuid,
                      account: x.account,
                      product: x.product,
                      action: x.action,
                      quantity: x.quantity,
                      price: x.price,
                      tickSizeLMT: x.tickSizeLMT,
                      tickDifferenceLMT: x.tickDifferenceLMT,
                      position: foundOrder.position,
                    });
                  }
                }
              }
            });

            if (
              !currentQuotes.current.find(
                (item) => item.product === jsonValidate.INS
              )
            ) {
              currentQuotes.current.push({
                product: jsonValidate.INS,
                ask: jsonValidate.AK,
                bid: jsonValidate.BD,
              });
            } else {
              currentQuotes.current = currentQuotes.current.map((item) => {
                if (item.product === jsonValidate.INS) {
                  return {
                    ...item,
                    ask: jsonValidate.AK,
                    bid: jsonValidate.BD,
                  };
                }
                return item;
              });
            }
          } else if (jsonValidate.RQT === "positions") {
            var tmpPositions = [];
            var tmpProducts = [];

            jsonValidate.POSITIONS.forEach((element) => {
              if (element["OD"]) {
                tmpPositions.push(element);
                if (!tmpProducts.includes(element["INS"]))
                  tmpProducts.push(element["INS"]);
              } else {
                element["OD"] = 0;
                tmpPositions.push(element);
                if (!tmpProducts.includes(element["INS"]))
                  tmpProducts.push(element["INS"]);
              }
            });
            tmpPositions.sort((a, b) => {
              return parseInt(a["OD"]) - parseInt(b["OD"]);
            });

            tmpProducts.forEach((product) => {
              var productPosition = tmpPositions.filter(
                (x) => x["INS"] === product
              );
              if (productPosition.length % 2 === 1) {
                // Odd number, there's a open position
                var openPosition = productPosition[productPosition.length - 1];

                setTransactionLogs((prevLogs) => [
                  ...prevLogs,
                  {
                    index: prevLogs.length,
                    type: "MESSAGE",
                    status: "FILLED - Previous Position",
                    orderID: openPosition.OD,
                    account: jsonValidate.AC,
                    product: openPosition.INS,
                    action:
                      openPosition.SD === "S"
                        ? "S"
                        : openPosition.SD === "L"
                        ? "B"
                        : "UNKNOWN",
                    quantity: openPosition.QT,
                    price: openPosition.PR,
                    position: "ENTER",
                    date: "-",
                  },
                ]);

                currentPosition.current.push({
                  account: jsonValidate.AC,
                  product: openPosition.INS,
                  fulfilled: true,
                  action:
                    openPosition.SD === "S"
                      ? "S"
                      : openPosition.SD === "L"
                      ? "B"
                      : "UNKNOWN",
                  position: "ENTER",
                  created: true,
                });
              }
            });
          } else if (jsonValidate.RQT === "orders") {
            jsonValidate.ORDERS.forEach((order) => {
              currentOrders.current.push({
                orderID: order.OD,
                product: order.INS,
                account: jsonValidate.AC,
                price: order.PR,
                action: order.SD,
                tickSizeLMT: 0,
                tickDifferenceLMT: 0,
                position: "",
                quantity: order.QT,
              });
            });
          } else if (jsonValidate.RQT === "cancel_order") {
            if (jsonValidate.RES !== "OK") {
              setTransactionLogs((prevLogs) => [
                ...prevLogs,
                {
                  index: prevLogs.length,
                  type: "ERROR - Cancel Order failure",
                  message: jsonValidate.MSG,
                  date: new Date().toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }),
                },
              ]);
            } else {
              // Remove from currentOrders
              currentOrders.current = currentOrders.current.filter(
                (x) => x.orderID !== jsonValidate.OD
              );

              currentOrderID.current = currentOrderID.current.filter(
                (y) => y.orderID !== jsonValidate.OD
              );
            }
          }
        }
      };
    }
  }, [qstSocket]);

  function processValidate(res) {
    var jsonValidate = JSON.parse(res);
    var jsonRes = jsonValidate.RES;
    if (jsonRes === "OK") {
      return true;
    }
    return false;
  }

  function validateHttp() {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", "https://localhost:8888/validate", false);
    xmlHttp.send(null);

    return xmlHttp.responseText;
  }

  const sendQSTMessage = (message) => {
    if (qstSocket) {
      qstSocket.send(message);
    }
  };

  function switchPlatform() {
    const newValue = !isDemo;
    setIsDemo(newValue);

    sendQSTMessage(
      JSON.stringify({
        RQT: "accounts",
        PV: newValue ? "PTS" : "QOR",
      })
    );
  }

  function onQSTConnectionMake() {
    const newQSTSocket = new WebSocket(api.QST_API);
    setQstSocket(newQSTSocket);
  }

  // FORM IMPLEMENTATION
  function onFormLotSizeChange(value) {
    if (value <= 0) {
      alert("Lot number need to be more than 0");
      setFormLotSize(formLotSize);
      return;
    }
    setFormLotSize(value);
  }
  // END FORM IMPLEMENTATION

  function AddStrategy() {
    if (formAccount && formProduct && formStrategy && formLotSize) {
      if (
        formAccount !== "" &&
        formProduct !== "" &&
        formStrategy !== "" &&
        formLotSize !== ""
      ) {
        const tmp = strategy.find(
          (x) => x.account === formAccount && x.product === formProduct
        );
        if (tmp) {
          alert(`Strategy on ${formProduct} already added`);
          return;
        }

        const tmpStrat = setting
          .find((x) => x.account === formAccount)
          .strategy.find((x) => x._id === formStrategy);

        const newStrategy = {
          account: formAccount,
          product: formProduct,
          strategy: formStrategy, //Strategy ID
          lotSize: formLotSize,
          type: formType,
          strategy_name: formStrategyName,
          positionFailsafe: tmpStrat.positionFailsafe
            ? tmpStrat.positionFailsafe
            : false,
          tickSizeLMT: tmpStrat.tickSizeLMT,
          tickDifferenceLMT: tmpStrat.tickDifferenceLMT,
          tickSitmpStrateMKT: tmpStrat.tickSitmpStrateMKT,
          tickDifferenceMKT: tmpStrat.tickDifferenceMKT,
          LMTExitTimeout: tmpStrat.LMTExitTimeout ? tmpStrat.LMTExitTimeout : 0,
          LMTEnterTimeout: tmpStrat.LMTEnterTimeout
            ? tmpStrat.LMTEnterTimeout
            : 0,
        };
        setStrategy([...strategy, newStrategy]);
      } else {
        alert("Please fill all the form");
      }
    }
  }

  function startExecution() {
    setStartingExecution(true);
    if (process.env.NODE_ENV !== "development") {
      if (strategy.length === 0) {
        alert("Please add strategy");
        return;
      } else if (qstConnected === false) {
        alert("QST is not connected");
        return;
      }
    }

    if (strategy.filter((x) => x.type === "" || !x.type).length > 0) return;

    if (process.env.NODE_ENV === "development" || qstSocket.readyState === 1) {
      const uniqueAccountsTmp = strategy.reduce((acc, curr) => {
        if (!acc.some((item) => item === curr.account)) {
          acc.push(curr.account);
        }
        return acc;
      }, []);

      setUniqueAccounts(uniqueAccountsTmp);
      executeAction.current = [];
      const newRvtSocket = io(api.SOCKET_URL, {
        query: {
          accounts: uniqueAccountsTmp,
        },
      });

      uniqueAccountsTmp.forEach((x) => {
        sendQSTMessage(
          JSON.stringify({
            RQT: "positions",
            PV: isDemo ? "PTS" : "QOR",
            AC: x,
          })
        );

        sendQSTMessage(
          JSON.stringify({
            RQT: "orders",
            PV: isDemo ? "PTS" : "QOR",
            AC: x,
          })
        );
      });

      setRvtSocket(newRvtSocket);
      localStorage.setItem("pastStrategyVersion", version);
      localStorage.setItem("pastStrategy", JSON.stringify(strategy));
      localStorage.setItem("pastMode", isDemo ? "DEMO" : "LIVE");
      setActiveStrategy(strategy);

      // Register quotes
      strategy
        .reduce((acc, curr) => {
          if (!acc.some((item) => item === curr.product)) {
            acc.push(curr.product);
          }
          return acc;
        }, [])
        .forEach((x) => {
          sendQSTMessage(
            JSON.stringify({
              RQT: "register",
              INS: x, // Instrument
            })
          );
        });
    }
  }

  function stopExecution() {
    setStartingExecution(false);
    autoReconnect.current = false;
    rvtSocket.disconnect();

    setTransactionLogs([]);
    executeAction.current = [];
    currentOrders.current = [];
    currentQuotes.current = [];
    currentPosition.current = [];
  }

  function resetExecution() {
    if (productToReset.length > 0) {
      setTransactionLogs((logs) =>
        logs.filter((x) => !productToReset.includes(x.product))
      );
      executeAction.current = executeAction.current.filter(
        (x) => !productToReset.includes(x.product)
      );
      currentOrders.current = currentOrders.current.filter(
        (x) => !productToReset.includes(x.product)
      );
      currentPosition.current = currentPosition.current.filter(
        (x) => !productToReset.includes(x.product)
      );
      setConfirmationModalShow(false);
      setProductToReset([]);
    } else {
      setConfirmationModalShow(false);
      setProductToReset([]);
    }
  }

  useEffect(() => {
    if (rvtSocket) {
      // Event handler for connection
      rvtSocket.on("connect", () => {
        setRvtConnected(true);
        console.log("Connected to server");
        autoReconnecting.current = false;
        autoReconnectCount.current = 0;
      });

      rvtSocket.on("error", (data) => {
        alert(data);
      });

      rvtSocket.on("disconnect", () => {
        setRvtConnected(false);
        setRvtSocket(null);

        if (autoReconnectCount.current >= 120) {
          autoReconnect.current = false;
          autoReconnecting.current = false;
          return;
        }

        if (autoReconnect.current === true) {
          autoReconnecting.current = true;
          setTimeout(() => {
            const newRvtSocket = io(api.SOCKET_URL, {
              query: {
                accounts: uniqueAccounts,
              },
            });
            setRvtSocket(newRvtSocket);
          }, 1000);
        }
        autoReconnect.current = true;
        autoReconnectCount.current = autoReconnectCount.current + 1;
      });

      rvtSocket.on("order", (data) => {
        activeStrategy
          .filter((x) => x.strategy === data.id)
          .forEach((x) => {
            var QTY = x.lotSize;
            if (data.multiplier) {
              QTY = QTY * data.multiplier;
            }

            var tmpType = x.type;
            // If exit position then always use MKT/ unless LMT/LMT
            if (!data.position.startsWith("EXIT-")) {
              // Check if MKT vs tick difference
              if (
                x.type === "MKT" &&
                data.tickSizeMKT * data.tickDifferenceMKT > 0
              ) {
                const quote = currentQuotes.current.find(
                  (y) => y.product === data.code
                );
                if (quote) {
                  // quote.ask || quote.bid
                  if (quote.ask && quote.bid) {
                    if (
                      (data.operation === "B" &&
                        Math.abs(quote.ask - data.price) >
                          Math.abs(
                            data.tickSizeMKT * data.tickDifferenceMKT
                          )) ||
                      (data.operation === "S" &&
                        Math.abs(quote.bid - data.price) >
                          Math.abs(data.tickSizeMKT * data.tickDifferenceMKT))
                    ) {
                      setTransactionLogs((prevLogs) => [
                        ...prevLogs,
                        {
                          index: prevLogs.length,
                          type: "MESSAGE",
                          status: "IGNORED - MKT PRICE EXCEEDS TICK DIFFERENCE",
                          date: new Date().toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }),
                          orderID: "-",
                          account: x.account,
                          product: x.product,
                          action: data.operation,
                          position: data.position,
                          quantity: QTY,
                          price:
                            data.price +
                            (data.operation === "B"
                              ? ` - ASK: ${quote.ask}`
                              : data.operation === "S"
                              ? ` - BID: ${quote.bid}`
                              : ""),
                        },
                      ]);
                      return;
                    }
                  }
                }
              } else if (x.type === "LMT/LMT") {
                tmpType = "LMT";
              }
            } else if (tmpType === "LMT/LMT") {
              tmpType = "LMT";
            } else {
              tmpType = "MKT";
            }

            if (x.positionFailsafe === true) {
              // Check for position
              if (data.position === "ENTER") {
                if (
                  currentPosition.current.find(
                    (y) =>
                      y.account === x.account &&
                      y.product === data.code &&
                      y.created !== false
                  )
                ) {
                  setTransactionLogs((prevLogs) => [
                    ...prevLogs,
                    {
                      index: prevLogs.length,
                      type: "MESSAGE",
                      status: "IGNORED - FOUND EXISTING POSITION",
                      orderID: "-",
                      date: new Date().toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }),
                      account: x.account,
                      product: x.product,
                      action: data.operation,
                      position: data.position,
                      quantity: QTY,
                      price: data.price,
                    },
                  ]);
                  return;
                } else {
                  currentPosition.current.push({
                    account: x.account,
                    product: data.code,
                    fulfilled: false,
                    action: data.operation,
                    position: data.position,
                    created: false,
                  });
                }
              } else if (data.position.startsWith("EXIT")) {
                if (
                  !currentPosition.current.find(
                    (y) =>
                      y.account === x.account &&
                      y.product === data.code &&
                      y.fulfilled === true &&
                      y.created === true
                  )
                ) {
                  setTransactionLogs((prevLogs) => [
                    ...prevLogs,
                    {
                      index: prevLogs.length,
                      type: "MESSAGE",
                      status: "IGNORED - ENTRY NOT FULFILLED",
                      orderID: "-",
                      account: x.account,
                      product: x.product,
                      action: data.operation,
                      position: data.position,
                      quantity: QTY,
                      price: data.price,
                      date: new Date().toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }),
                    },
                  ]);
                  return;
                } else {
                  if (
                    currentPosition.current.find(
                      (y) =>
                        y.account === x.account &&
                        y.product === data.code &&
                        y.fulfilled === true &&
                        y.created === true
                    ).action !== data.operation
                  ) {
                    currentPosition.current = currentPosition.current.filter(
                      (y) =>
                        !(
                          y.account === x.account &&
                          y.product === data.code &&
                          y.fulfilled === true &&
                          y.created === true
                        )
                    );
                  } else {
                    setTransactionLogs((prevLogs) => [
                      ...prevLogs,
                      {
                        index: prevLogs.length,
                        type: "MESSAGE",
                        status: "IGNORED - SAME OPERATION",
                        orderID: "-",
                        account: x.account,
                        product: x.product,
                        action: data.operation,
                        position: data.position,
                        quantity: QTY,
                        price: data.price,
                        date: new Date().toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }),
                      },
                    ]);
                    return;
                  }
                }
              } else if (data.position === "REVERSAL") {
                if (
                  !currentPosition.current.find(
                    (y) =>
                      y.account === x.account &&
                      y.product === data.code &&
                      y.fulfilled === true &&
                      y.created === true
                  ) // if Enter not fulfilled
                ) {
                  setTransactionLogs((prevLogs) => [
                    ...prevLogs,
                    {
                      index: prevLogs.length,
                      type: "MESSAGE",
                      status: "IGNORED - ENTRY NOT FULFILLED",
                      orderID: "-",
                      account: x.account,
                      product: x.product,
                      action: data.operation,
                      position: data.position,
                      quantity: QTY,
                      price: data.price,
                      date: new Date().toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }),
                    },
                  ]);
                  return;
                } else {
                  // if enter fulfilled
                  if (
                    currentPosition.current.find(
                      (y) =>
                        y.account === x.account &&
                        y.product === data.code &&
                        y.fulfilled === true &&
                        y.position === "ENTER" &&
                        y.action !== data.operation &&
                        y.created === true
                    )
                    // if enter so do reversal
                  ) {
                    currentPosition.current = currentPosition.current.map(
                      (y) => {
                        if (
                          y.account === x.account &&
                          y.product === data.code &&
                          y.fulfilled === true &&
                          y.created === true
                        ) {
                          return {
                            ...y,
                            fulfilled: false,
                            action: data.operation,
                            created: false,
                          };
                        }
                        return y;
                      }
                    );
                  } else {
                    // else enter ignore
                    setTransactionLogs((prevLogs) => [
                      ...prevLogs,
                      {
                        index: prevLogs.length,
                        type: "MESSAGE",
                        status: "IGNORED - SAME OPERATION",
                        orderID: "-",
                        account: x.account,
                        product: x.product,
                        action: data.operation,
                        position: data.position,
                        quantity: QTY,
                        price: data.price,
                        date: new Date().toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }),
                      },
                    ]);
                    return;
                  }
                }
              }
            }

            sendQSTMessage(
              JSON.stringify({
                RQT: "place_order", // Request Type
                PV: isDemo ? "PTS" : "QOR", // Provider
                AC: x.account, // Account
                SD: data.operation, // Side: B / S
                QT: `${QTY}`, // Quantity
                INS: data.code, // Instrument
                TP: tmpType, // Order Type
                PR: data.price, // Price
                LM: data.price, // Limit
                LF: "DAY", // Lifetime: DAY, GTC, GTD(mm/dd/yyyy)
                CNF: "OFF", // [Optional] DEF/ON/OFF
              })
            );

            const orderUUID = uuidv4();
            executeAction.current.push({
              uuid: orderUUID,
              account: x.account,
              product: data.code,
              action: data.operation,
              quantity: QTY,
              price: data.price,
              tickSizeLMT: data.tickSizeLMT,
              tickDifferenceLMT: data.tickDifferenceLMT,
              position: data.position,
            });

            if (x.type === "LMT/LMT" && data.position.startsWith("EXIT-")) {
              if (x.LMTExitTimeout > 0) {
                setTimeout(() => {
                  if (
                    currentOrderID.current.filter((x) => x.uuid === orderUUID)
                      .length !== 0
                  ) {
                    // Cancel Order
                    sendQSTMessage(
                      JSON.stringify({
                        RQT: "cancel_order",
                        PV: isDemo ? "PTS" : "QOR", // Provider
                        AC: x.account, // Account
                        OD: currentOrderID.current.find(
                          (x) => x.uuid === orderUUID
                        ).orderID, // Order Id
                        CNF: "OFF", // [Optional] DEF/ON/OFF
                      })
                    );

                    // Update transaction logs
                    setTransactionLogs((prevLogs) => {
                      const newLogs = prevLogs.map((x) => {
                        if (
                          x.orderID ===
                          currentOrderID.current.find(
                            (y) => y.uuid === orderUUID
                          ).orderID
                        ) {
                          return {
                            index: x.index,
                            type: "MESSAGE",
                            status: "CANCELLED - Switch to MKT order",
                            account: x.account,
                            product: x.product,
                            orderID: x.orderID,
                            action: x.action,
                            quantity: x.quantity,
                            price: x.price,
                            position: x.position,
                            date: x.date,
                          };
                        }
                        return x;
                      });
                      return newLogs;
                    });

                    executeAction.current.push({
                      uuid: orderUUID,
                      account: x.account,
                      product: data.code,
                      action: data.operation,
                      quantity: QTY,
                      price: data.price,
                      tickSizeLMT: data.tickSizeLMT,
                      tickDifferenceLMT: data.tickDifferenceLMT,
                      position: data.position,
                    });

                    // Create new MKT order
                    sendQSTMessage(
                      JSON.stringify({
                        RQT: "place_order", // Request Type
                        PV: isDemo ? "PTS" : "QOR", // Provider
                        AC: x.account, // Account
                        SD: data.operation, // Side: B / S
                        QT: `${QTY}`, // Quantity
                        INS: data.code, // Instrument
                        TP: "MKT", // Order Type
                        PR: data.price, // Price
                        LM: data.price, // Limit
                        LF: "DAY", // Lifetime: DAY, GTC, GTD(mm/dd/yyyy)
                        CNF: "OFF", // [Optional] DEF/ON/OFF
                      })
                    );
                  }
                  // If order not fulfilled, change to MKT order
                }, x.LMTExitTimeout * 60000);
              }
            } else if (tmpType === "LMT" && data.position.startsWith("ENTER")) {
              if (x.LMTEnterTimeout > 0) {
                setTimeout(() => {
                  if (
                    currentOrderID.current.filter((x) => x.uuid === orderUUID)
                      .length !== 0
                  ) {
                    // Cancel Order
                    sendQSTMessage(
                      JSON.stringify({
                        RQT: "cancel_order",
                        PV: isDemo ? "PTS" : "QOR", // Provider
                        AC: x.account, // Account
                        OD: currentOrderID.current.find(
                          (x) => x.uuid === orderUUID
                        ).orderID, // Order Id
                        CNF: "OFF", // [Optional] DEF/ON/OFF
                      })
                    );

                    // Update transaction logs
                    setTransactionLogs((prevLogs) => {
                      const newLogs = prevLogs.map((x) => {
                        if (
                          x.orderID ===
                          currentOrderID.current.find(
                            (y) => y.uuid === orderUUID
                          ).orderID
                        ) {
                          return {
                            index: x.index,
                            type: "MESSAGE",
                            status: "CANCELLED - Order timeout",
                            account: x.account,
                            product: x.product,
                            orderID: x.orderID,
                            action: x.action,
                            quantity: x.quantity,
                            price: x.price,
                            position: x.position,
                            date: x.date,
                          };
                        }
                        return x;
                      });
                      return newLogs;
                    });
                  }
                  // If order not fulfilled, change to MKT order
                }, x.LMTEnterTimeout * 60000);
              }
            }
          });
      });

      setTimeout(() => {
        sendingActive();
      }, 5000);
    }
  }, [rvtSocket]);

  function sendingActive() {
    rvtSocket.emit("active", activeStrategy);

    setTimeout(() => {
      sendingActive();
    }, 60000);
  }

  function DEBUGMODE() {
    api
      .postDataNoRefresh("qst/login", [
        { account: "37887", name: "Acc1", provider: "PTS" },
        { account: "37886", name: "Acc2", provider: "PTS" },
        { account: "37885", name: "Acc3", provider: "PTS" },
      ])
      .then((res) => {
        setSetting(res);
        if (res.length === 1) {
          setFormAccount(res[0].account);
        }

        var newStrat = [];
        const pastStrategy = localStorage.getItem("pastStrategy");
        const pastStrategyVersion = localStorage.getItem("pastStrategyVersion");
        if (pastStrategy && pastStrategyVersion === version) {
          const pastStratObj = JSON.parse(pastStrategy);
          pastStratObj.forEach((x) => {
            res.forEach((y) => {
              if (y.account === x.account) {
                y.strategy.forEach((z) => {
                  if (z._id === x.strategy && z.qstCode === x.product) {
                    x.strategy_name = z.name;
                    newStrat = [...newStrat, x];
                  }
                });
              }
            });
          });
          setStrategy(newStrat);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            alert("Unauthorized");
            setIsUnauthorized(true);
            if (qstSocket) qstSocket.close();
          }
        }
        console.error(err);
      });
  }

  return (
    <>
      <Container fluid className="mb-5">
        {process.env.NODE_ENV === "development" && (
          <>
            <Row>
              <Col>
                <Button onClick={() => DEBUGMODE()}>DEBUG</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea value={DEBUGMESSAGE}></textarea>
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-5">
          <Col lg="6" md="8" xs="4" className="ms-5">
            <Row>
              <Col lg="5" md="5">
                <h1>RiveTrading Connection</h1>
              </Col>
              <Col lg="1" md="1">
                <h1>:</h1>
              </Col>
              <Col>
                <h1>
                  <span
                    className={rvtConnected ? "text-success" : "text-danger"}
                  >
                    {rvtConnected ? "Connected" : "Disconnected"}
                  </span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg="5" md="5">
                <h1>QST Connection</h1>
              </Col>
              <Col lg="1" md="1">
                <h1>:</h1>
              </Col>
              <Col>
                <h1>
                  <span
                    className={qstConnected ? "text-success" : "text-danger"}
                  >
                    {qstConnected ? "Connected" : "Disconnected"}
                  </span>
                  {qstConnected === false && (
                    <Button
                      size="lg"
                      className="ms-3"
                      variant={"primary"}
                      onClick={onQSTConnectionMake}
                    >
                      Retry
                    </Button>
                  )}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg="5" md="5">
                <h1>QST Platform</h1>
              </Col>
              <Col lg="1" md="1">
                <h1>:</h1>
              </Col>
              <Col>
                <h1>
                  <span className={isDemo ? "text-warning" : "text-success"}>
                    {isDemo ? "Demo" : "Live"}
                  </span>
                  {rvtConnected === false &&
                    autoReconnecting.current === false && (
                      <Button
                        size="lg"
                        className="ms-3"
                        disabled={qstConnected === false}
                        variant={isDemo ? "primary" : "info"}
                        onClick={switchPlatform}
                      >
                        {isDemo ? "Switch to Live" : "Switch to Demo"}
                      </Button>
                    )}
                </h1>
              </Col>
            </Row>
            {setting && (
              <Row>
                <Col lg="5" md="5">
                  <h1>QST Account</h1>
                </Col>
                <Col lg="1" md="1">
                  <h1>:</h1>
                </Col>
                <Col>
                  <h1>
                    {setting.map((x) =>
                      setting.indexOf(x) === setting.length - 1
                        ? x.account
                        : `${x.account} | `
                    )}
                  </h1>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {!setting ? (
          <>
            <Row className="mt-5">
              <Col lg="7" className="text-center">
                {IsUnauthorized && (
                  <h4 className="text-danger">Unauthorized</h4>
                )}
                {qstConnected && <Spinner />}
                {!qstConnected && (
                  <h4 className="text-danger">Not connected to QST</h4>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            {rvtConnected === true ? (
              <>
                <Row className="mt-3">
                  <Col lg={7}>
                    <h1 className="text-center">Executing</h1>
                  </Col>
                </Row>
                <Row className="mt-5 ms-3">
                  <Col lg="7">
                    <Table
                      striped
                      bordered
                      hover
                      className="text-center table-dark"
                    >
                      <thead>
                        <tr>
                          <th>Account</th>
                          {/* <th>Product</th> */}
                          <th>Strategy</th>
                          <th>Type</th>
                          <th>Lot Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        {strategy.map((e, index) => (
                          <tr key={index}>
                            <td>{e.account}</td>
                            {/* <td>{e.product}</td> */}
                            <td>{e.strategy_name}</td>
                            <td>{e.type}</td>
                            <td>{e.lotSize}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className="mt-3 ms-3">
                  <Col lg={7} className="text-center">
                    <Button
                      size="md"
                      className="ms-3"
                      variant={"danger"}
                      disabled={strategy.length === 0}
                      onClick={() => stopExecution()}
                    >
                      Stop Execution
                    </Button>
                    <Button
                      size="md"
                      className="ms-3"
                      variant={"warning"}
                      onClick={() => setConfirmationModalShow(true)}
                    >
                      Reset Execution
                    </Button>
                  </Col>
                </Row>
                <Row className="ms-3 mt-5 text-center">
                  <Col lg={7}>
                    <h4>Execution logs</h4>
                  </Col>
                </Row>
                <Row className="mt-3 ms-3">
                  <Col
                    lg="7"
                    style={{ maxHeight: 500 }}
                    className="overflow-auto"
                  >
                    <Table
                      striped
                      bordered
                      hover
                      className="table-dark text-center"
                    >
                      <thead>
                        <tr>
                          <th>Account</th>
                          <th>Product</th>
                          <th>Action</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Position</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionLogs
                          .sort((a, b) => {
                            if (a.index > b.index) return -1;
                            if (a.index < b.index) return 1;
                            return 0;
                          })
                          .map((e, index) => (
                            <>
                              {e.type === "ERROR" ? (
                                <tr key={index}>
                                  <td
                                    colSpan={8}
                                    className="bg-warning text-white fw-bold"
                                  >
                                    {e.message}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={index}>
                                  <td>{e.account}</td>
                                  <td>{e.product}</td>
                                  <td
                                    className={
                                      e.action === "B"
                                        ? "text-success"
                                        : e.action === "S"
                                        ? "text-danger"
                                        : "text-warning"
                                    }
                                  >
                                    <span className="fw-bold">
                                      {e.action === "B"
                                        ? "BUY"
                                        : e.action === "S"
                                        ? "SELL"
                                        : e.action}
                                    </span>
                                  </td>
                                  <td>{e.price}</td>
                                  <td>{e.quantity}</td>
                                  <td>{e.position}</td>
                                  <td
                                    className={
                                      e.status.startsWith("ORDER")
                                        ? "text-primary"
                                        : e.status.startsWith("CANCELLED") ||
                                          e.status.startsWith("IGNORED")
                                        ? "text-warning"
                                        : e.status.startsWith("FILLED")
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    <span className="fw-bold">{e.status}</span>
                                  </td>
                                  <td>{e.date}</td>
                                </tr>
                              )}
                            </>
                          ))}
                        {transactionLogs.length === 0 && (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No transaction logs
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Modal
                  show={confirmationModalShow}
                  onHide={() => setConfirmationModalShow(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                      Are you sure?
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-dark">
                    <Row>
                      <Col>Are you sure to reset execution?</Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>Product to reset:</Col>
                    </Row>
                    {activeStrategy.map((strat, index) => (
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            label={strat.product}
                            type="checkbox"
                            id={"reset_" + strat.product}
                            name={"reset_" + strat.product}
                            checked={productToReset.includes(strat.product)}
                            onChange={(e) =>
                              e.target.checked
                                ? setProductToReset([
                                    ...productToReset,
                                    strat.product,
                                  ])
                                : setProductToReset(
                                    productToReset.filter(
                                      (x) => x !== strat.product
                                    )
                                  )
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={() => setConfirmationModalShow(false)}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={() => resetExecution()}>
                      Confirm
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            ) : autoReconnecting.current === true ? (
              <>
                <Row className="mt-5">
                  <Col lg="1" md="1" xs="1"></Col>
                  <Col lg="4" className="text-center">
                    <h4>Reconnecting...</h4>
                  </Col>
                </Row>
              </>
            ) : autoReconnecting.current === false &&
              autoReconnect.current === false ? (
              <>
                <Row className="mt-5">
                  <Col lg="1" md="1" xs="1"></Col>
                  <Col lg="4" className="text-center">
                    <h4>Connection dropped. Please refresh.</h4>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="mt-5">
                  <Col lg="6" md="8" xs="4" className="ms-5">
                    {/* QST Account if multiple */}
                    <Row>
                      {setting.length > 1 && (
                        <>
                          <Col lg={2} md={6} xs={6}>
                            <h5>QST Account</h5>
                          </Col>
                          <Col>
                            <Form.Select
                              className="w-auto"
                              onChange={(e) => {
                                setFormAccount(e.target.value);
                                setFormProduct("");
                                setFormStrategy("");
                                setFormStrategyName("");
                              }}
                              value={formAccount}
                              defaultValue={""}
                            >
                              <option disabled value="">
                                Select Account
                              </option>
                              {setting.map((e, index) => (
                                <option key={index} value={e.account}>
                                  {e.account} - {e.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                    </Row>
                    {/* Available Products & Lot Size */}
                    <Row className="mt-3">
                      <Col lg={6} md={12} xs={12}>
                        <Row>
                          <Col lg={4} md={6} xs={6}>
                            <h5>Available Product</h5>
                          </Col>
                          <Col>
                            <Form.Select
                              className="w-50 customDropdownTheme"
                              onChange={(e) => {
                                setFormProduct(e.target.value);
                                setFormStrategy("");
                              }}
                              disabled={formAccount ? false : true}
                              value={formProduct}
                              defaultValue={""}
                            >
                              <option disabled value="">
                                Select Product
                              </option>
                              {formAccount &&
                                setting
                                  .find((x) => x.account === formAccount)
                                  .strategy.reduce((acc, curr) => {
                                    if (
                                      !acc.some(
                                        (item) => item.qstCode === curr.qstCode
                                      )
                                    ) {
                                      acc.push(curr);
                                    }
                                    return acc;
                                  }, [])
                                  .map((e, index) => (
                                    <option key={index} value={e.qstCode}>
                                      {e.qstCode}
                                    </option>
                                  ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <Row>
                          <Col lg={4} md={6} xs={6}>
                            <h5>Lot Size</h5>
                          </Col>
                          <Col>
                            <Form.Control
                              size="sm"
                              className="w-50"
                              type="number"
                              placeholder="Enter number"
                              min={1}
                              value={formLotSize}
                              onChange={(e) =>
                                onFormLotSizeChange(e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* Available Strategy & Order Type */}
                    <Row className="mt-3">
                      <Col lg={6} md={12} xs={12}>
                        <Row>
                          <Col lg={4} md={6} xs={6}>
                            <h5>Available Strategy</h5>
                          </Col>
                          <Col>
                            <Form.Select
                              className="w-50 customDropdownTheme"
                              disabled={formProduct ? false : true}
                              onChange={(e) => {
                                setFormStrategy(e.target.value);
                                setFormStrategyName(
                                  e.target.options[e.target.selectedIndex].text
                                );
                              }}
                              value={formStrategy}
                              defaultValue={""}
                            >
                              <option disabled value="">
                                Select Strategy
                              </option>
                              {formProduct &&
                                setting
                                  .find((x) => x.account === formAccount)
                                  .strategy.filter(
                                    (x) => x.qstCode === formProduct
                                  )
                                  .map((e, index) => (
                                    <option key={index} value={e._id}>
                                      {e.name}
                                    </option>
                                  ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <Row>
                          <Col lg={4} md={6} xs={6}>
                            <h5>Order Type</h5>
                          </Col>
                          <Col>
                            <Form.Select
                              className="w-50 customDropdownTheme"
                              onChange={(e) => {
                                setFormType(e.target.value);
                              }}
                              value={formType}
                            >
                              <option value="LMT">LMT/MKT</option>
                              <option value="MKT">MKT/MKT</option>
                              <option value="LMT/LMT">LMT/LMT</option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3 ms-5">
                  <Col>
                    <Button
                      size="md"
                      className="ms-3 customButton"
                      disabled={startingExecution}
                      onClick={() => AddStrategy()}
                    >
                      Add Strategy
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-5 ms-3 mb-5">
                  <Col lg="7">
                    <Table
                      striped
                      bordered
                      hover
                      className="text-center table-dark"
                    >
                      <thead>
                        <tr>
                          <th>Account</th>
                          {/* <th>Product</th> */}
                          <th>Strategy</th>
                          <th>Type</th>
                          <th>Lot Size</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {strategy.length === 0 && (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No strategy added
                            </td>
                          </tr>
                        )}
                        {strategy.map((e, index) => (
                          <tr key={index}>
                            <td>{e.account}</td>
                            {/* <td>{e.product}</td> */}
                            <td>{e.strategy_name}</td>
                            <td>{e.type}</td>
                            <td>{e.lotSize}</td>
                            <td>
                              <Button
                                size="sm"
                                variant="danger"
                                disabled={startingExecution}
                                onClick={() => {
                                  const newStrategy = strategy.filter(
                                    (x) => x !== e
                                  );
                                  setStrategy(newStrategy);
                                }}
                              >
                                <i className="fa fa-trash-o"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className="mt-3 ms-5">
                  <Col>
                    <Button
                      size="md"
                      className="ms-3 customButton"
                      disabled={strategy.length === 0 || startingExecution}
                      onClick={() => startExecution()}
                    >
                      Start Execution
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
export default QST;
